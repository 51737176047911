import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import mtdImg from "../images/metadonors.png"

import Header from "./header"
import "./layout.css"
import { Grommet, Box, Image, Footer, Paragraph } from "grommet"
import theme from "../theme"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Grommet
      theme={theme}
      full
    >
      <Header siteTitle={data.site.siteMetadata.title} />

      <Box as="main" overflow="auto">
        {children}
      </Box>

      <Footer justify="center" pad="large">
        <Box justify="center">
          <Paragraph textAlign="center">
            distribuito da
          </Paragraph>
          <a href="https://metadonors.it" target="_blank" rel="noopener noreferrer">
            <Image src={mtdImg} width={250}/>
          </a>
          <Paragraph textAlign="center">
            © {new Date().getFullYear()}, Metadonors
          </Paragraph>

          <Paragraph textAlign="center">
            <a href="https://www.iubenda.com/privacy-policy/93603733" className="iubenda-white iubenda-embed" title="Privacy Policy " target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </Paragraph>
        </Box>
      </Footer>
    </Grommet>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout