import React from "react"
import styled from "styled-components"
import { Button, Box } from "grommet"

const StyledButton = styled(Button)`
  padding: 20px;
  width: auto;
`

const ActionButton = ({ to, padded = true, ...props }) => {
  const handleClick = React.useCallback(() => {
    if (to) {
      window.history.pushState(null, null, to);

      document.querySelector(to).scrollIntoView({
        behavior: "smooth",
      })
    }
  }, [to])
  return (
    <Box direction="row">
      {padded ? (
        <StyledButton {...props} onClick={handleClick} />
      ) : (
        <Button {...props} onClick={handleClick} />
      )}
    </Box>
  )
}

export default React.memo(ActionButton)
