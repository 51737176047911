import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.svg"
import { Box, Image, ResponsiveContext } from "grommet"
import ActionButton from './common/actionButton'

const Header = ({ siteTitle }) => {
  const size = React.useContext(ResponsiveContext)
  
  return (
  <Box pad="medium" margin={{horizontal: "large"}} direction="row" justify="between">
    <Link to="/">
      <Image src={logo} alt={siteTitle}  />
    </Link>
    {size !== "small" && (
      <ActionButton padded={false} label="Richiedi un preventivo " to="#contatti" />
    )}
  </Box>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
