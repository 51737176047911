import { grommet } from "grommet/themes"

const theme = {
  ...grommet,
  global: {
    ...grommet.global,
    colors: {
      ...grommet.colors,
      brand: "#1EA4A6",
      brandDark: "#1A8082",
    },
    font: {
      family: "QuickSand",
    },
  },
  button: {
    primary: {
      color: { dark: "#1A8082", light: "#1EA4A6" }
    },
    border: {
      width: "0px",
      radius: "0px",
      padding: {
        vertical: "44px"
      }
    }
  }
}

console.log(theme)

export default theme
